import {
  LOADED_FAIL_USER_INFO,
  LOADED_USER_INFO,
  LOAD_USER_INFO,
  PROFILE_UPDATED,
  UPDATE_USER_FAILED,
  RESET,
  RETRIEVE_OLD_OFFICE,
  RETRIEVE_OLD_OFFICE_FAIL,
} from '../actions/types';
import isEmpty from 'lodash/isEmpty';

function flattenObject(obj) {
  const result = {};
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      const flattened = flattenObject(obj[key]);
      for (const flattenedKey in flattened) {
        result[flattenedKey] = flattened[flattenedKey];
      }
    } else {
      result[key] = obj[key];
    }
  }
  return result;
}

const initialState = {
  loading: true,
  error: false,
  errorMessage: '',
  retrievedComplete: false,
  billingpractices: [],
  userBillingPractices: [
    {
      status: '',
      userBillingPracticeId: {
        billingpractice: {
          billingId: null,
          billing_apt: null,
          billing_city: '',
          billing_notes: '',
          billing_phone: '',
          billing_state: '',
          billing_street: '',
          billing_zip: '',
          carrierGroupId: null,
          hibernateLazyInitializer: {},
          id: null,
          license_number: null,
          officeHours: '',
          officeName: '',
          practiceId: null,
          practice_apt: null,
          practice_city: '',
          practice_notes: '',
          practice_phone: '',
          practice_state: '',
          practice_street: '',
          practice_zip: '',
          qbo_customer_id: null,
          standingId: null,
        },
      },
    },
  ],
  email: '',
  firstName: '',
  lastName: '',
  isStatus: '',
  license: null,
  mainContactEmail: '',
  mainContactFirstName: '',
  mainContactLastName: '',
  phone: '',
  updated: false,
  userId: '',
  isStatus: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET:
      return {
        ...state,
        error: false,
        errorMessage: '',
        loading: false,
        retrievedComplete: false,
      };
    case UPDATE_USER_FAILED:
      return {
        ...state,
        error: true,
        updateFailed: true,
        message: action.payload,
        loading: false,
        retrievedComplete: false,
      };
    case PROFILE_UPDATED:
      return {
        ...state,
        loading: false,
        updated: true,
        retrievedComplete: false,
      };
    case LOADED_USER_INFO:
      const bp = action.payload.userBillingPractices.map((a) => {
        return flattenObject(a);
      });
      return {
        ...state,
        ...action.payload,
        updated: false,
        loading: false,
        billingpractices: bp,
        error: false,
        errorMessage: '',
        retrievedComplete: false,
      };
    case LOAD_USER_INFO:
      return {
        ...state,
        loading: true,
        updated: false,
        retrievedComplete: false,
      };
    case LOADED_FAIL_USER_INFO:
      return {
        ...state,
        message: action.payload,
        loading: false,
        error: true,
        retrievedComplete: false,
      };
    case RETRIEVE_OLD_OFFICE:
      const billing = [...state.billingpractices];
      if (!isEmpty(action.payload)) {
        billing.push(flattenObject(action.payload.userBillingPractices));
      }
      return {
        ...state,
        loading: false,
        erorr: false,
        errorMessage: '',
        billingpractices: billing,
        retrievedComplete: true,
      };
    case RETRIEVE_OLD_OFFICE_FAIL:
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
        retrievedComplete: false,
      };
    default:
      return state;
  }
};

export default userReducer;
