// cardReducer.js
import {
  CARD_FETCHING,
  CARD_FETCH_SUCCESS,
  CARD_FETCH_FAILED,
} from '../actions/types';

const initialState = {
  loading: false,
  error: false,
  errorMessage: '',
  cards: [],
  customer: {},
};

export default function cardReducer(state = initialState, action) {
  switch (action.type) {
    case CARD_FETCHING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: '',
      };

    case CARD_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        cards: action.payload.cards,
        customer: action.payload.customer,
      };

    case CARD_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload || 'Failed to fetch cards.',
        cards: [],
        customer: {},
      };

    default:
      return state;
  }
}
